import React, { Component } from "react";
import Dropzone from "../Dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase"; // Ensure correct Firebase import
import Spacer from "../../tools/Spacer";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

const htmlDocx = require("html-docx-js/dist/html-docx");
const openai = require("openai");

// Initialize OpenAI API client
openai.apiKey = "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"; // Replace with your OpenAI API key

const DEFAULT_PARAMS = {
  model: "text-davinci-003",
  temperature: 0.4,
  max_tokens: 2000,
  top_p: 1,
  frequency_penalty: 0.2,
  presence_penalty: 0,
};

const generatePrompt = (question) => {
  return `Please provide feedback and scores for the following grant proposal in the following categories: overall quality, clarity, feasibility, impact, and innovation.
  also include general information on how their grant could be improved.\n
  ${question}
  Summary:`;
};

const generatePromptDoc = (question, template) => {
  return `${question}\n${template}\n
   Create a Grant application with the given information in HTML (only body format). Write a 500-word detailed essay requesting the grant.`;
};

const convertAndDownload = (htmlContent) => {
  console.log("Convert HTML");
  const docx = htmlDocx.asBlob(htmlContent);
  saveAs(docx, "converted_document.docx");
};

export default class GrantChecker extends Component {
  constructor() {
    super();
    this.state = {
      file: null,
      loading: false,
      Myhtml: "", // ✅ Fix: Defined Myhtml inside the state
    };
    this.processFile = this.processFile.bind(this);
  }

  async checkSubscription() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error("User not logged in.");
      window.location.href = "/login";
      return false;
    }

    try {
      const userRef = collection(db, "users");
      const userQuery = query(userRef, where("email", "==", user.email));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        const expirationDate = new Date(docData.exp);
        const currentDate = new Date();

        if (currentDate > expirationDate) {
          console.warn("Subscription expired.");
          window.location.href = "#/payment";
          return false;
        }
        return true;
      } else {
        console.warn("User document not found.");
        window.location.href = "/payment";
        return false;
      }
    } catch (error) {
      console.error("Error checking subscription:", error.message);
      return false;
    }
  }

  async getRating(params = {}, q) {
    this.setState({ loading: true });

    const auth = getAuth();
    const user = auth.currentUser;

    let temp = `\n Name of Company: ${user?.data?.name || "N/A"} \n Year of Foundation: ${user?.data?.yearFounded || "N/A"}\n Organization Mission: ${user?.data?.mission || "N/A"}\n
    EIN Number: ${user?.data?.EIN || "N/A"}\n NTEE: ${user?.data?.NTEE || "N/A"}\n`;

    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${openai.apiKey}`,
      },
      body: JSON.stringify({
        model: "text-davinci-003",
        prompt: generatePromptDoc(q, temp),
        temperature: 0.4,
        max_tokens: 2000,
        top_p: 1,
        frequency_penalty: 0.2,
        presence_penalty: 0,
      }),
    };

    const response1 = await fetch("https://api.openai.com/v1/completions", requestOptions1);
    const data1 = await response1.json();

    let Myhtml = data1.choices && data1.choices.length > 0 ? data1.choices[0].text : "Error: No response generated.";

    this.setState({ loading: false, Myhtml }); // ✅ Fix: Update Myhtml in state

    const requestOptions2 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${openai.apiKey}`,
      },
      body: JSON.stringify({
        model: "text-davinci-003",
        prompt: generatePrompt(q),
        temperature: 0.4,
        max_tokens: 2000,
        top_p: 1,
        frequency_penalty: 0.2,
        presence_penalty: 0,
      }),
    };

    const response2 = await fetch("https://api.openai.com/v1/completions", requestOptions2);
    const data2 = await response2.json();

    let fullString = data2.choices && data2.choices.length > 0 ? data2.choices[0].text : "Error: No response generated.";

    console.log("Generated Grant Feedback:", fullString);
    this.setState({ loading: false });
  }

  async processFile(file) {
    console.log("Processing file:", file);

    const hasSubscription = await this.checkSubscription();
    if (!hasSubscription) return;

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      console.log("File loaded:", fileReader.result);
      const text = fileReader.result;

      this.setState({ file, text });
      this.getRating({}, String(text));
    };
    fileReader.readAsText(file);
  }

  render() {
    const { file, loading, Myhtml } = this.state;

    return (
      <div className="text-center container mx-auto my-10 p-6 border rounded">
        <h2 className="font-bold my-4 text-xl">Grant Checker</h2>
        <p className="text-sm my-4">Upload your grant application and receive feedback and suggestions.</p>
        
        <Dropzone process={this.processFile}></Dropzone>

        {loading ? (
          <div className="flex items-center justify-center h-screen">
            <div className="flex flex-col items-center">
              <Spacer height={"50px"} />
              <img src="https://shortpixel.com/img/spinner2.gif" width="128" height="128" alt="Loading"/>
              <Spinner animation="border" />
            </div>
          </div>
        ) : null}

        {file && (
          <div className="border-t p-4 rounded bg-gray-100 mt-4">
            <div className="container mb-4"></div>
            <div className="flex justify-between">
              <div>
                <button className="btn btn-primary mr-2 bg-blue-500 text-white rounded px-3 py-2" onClick={() => convertAndDownload(Myhtml)}>
                  Download a template Request for funds proposal!
                </button>
                <button className="btn btn-primary bg-blue-500 text-white rounded px-3 py-2">
                  Email Suggestions
                </button>
              </div>
              <div>
                <span className="btn btn-success rounded-full mr-2 bg-green-500 p-3">
                  <FontAwesomeIcon icon={faThumbsUp} />
                </span>
                <span className="btn btn-danger rounded-full bg-red-500 p-3">
                  <FontAwesomeIcon icon={faThumbsDown} />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
