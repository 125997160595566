import { connect } from 'react-redux';
import { useState, useEffect } from "react";
import Input from "../../components/input";
import { collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { getAuth } from "firebase/auth";

const mapStateToProps = (props) => (props);

export default connect(mapStateToProps)(function Editprofile() { 
    const [name, setName] = useState("");
    const [expirationDate, setExpirationDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const auth = getAuth();
    const user = auth.currentUser;

    let email = user?.email || "";

    useEffect(() => {
        if (user) {
            fetchUserData(user.email);
        }
    }, [user]);

    const fetchUserData = async (email) => {
        try {
            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("email", "==", email));
            const querySnapshot = await getDocs(userQuery);

            if (!querySnapshot.empty) {
                const userData = querySnapshot.docs[0].data();
                setName(userData.name || "");
                if (userData.exp) {
                    setExpirationDate(new Date(userData.exp).toLocaleString());
                } else {
                    setExpirationDate("Not Available");
                }
            } else {
                setExpirationDate("No Subscription Found");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setExpirationDate("Error retrieving data");
        } finally {
            setLoading(false);
        }
    };

    const updateUserName = async (e) => {
        e.preventDefault();
        if (!name.trim()) {
            alert("Name cannot be empty");
            return;
        }
        setSaving(true);
        try {
            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("email", "==", email));
            const querySnapshot = await getDocs(userQuery);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, "users", userDoc.id), {
                    name: name
                });
                alert("Name updated successfully!");
            }
        } catch (error) {
            console.error("Error updating name:", error);
            alert("Error updating name, please try again.");
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="reset">
            <main>
                <div className="reset__box">
                    <div className="reset__form">
                        <form onSubmit={updateUserName}>
                            <h1>Edit Profile Details</h1>
                            <center>
                                <table border="3" className="reset__table">
                                    <tr>
                                        <td><b>Name: </b></td>
                                        <td>
                                            <input
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="border rounded p-2 w-full"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Email: </b></td>
                                        <td>{email}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Subscription Expiration Date: </b></td>
                                        <td>
                                            {loading ? "Loading..." : expirationDate}
                                        </td>
                                    </tr>
                                </table>
                            </center>

                            <div className="login__bottom mt-4">
                                <button
                                    className="btn bg-blue-500 text-white px-4 py-2 rounded"
                                    type="submit"
                                    disabled={saving}
                                >
                                    {saving ? "Saving..." : "Update Name"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
});
