import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Input from "../../components/input";
import { Link } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";

const mapStateToProps = (props) => (props);

export default connect(mapStateToProps)(function Register({setNavbar, user}) {
    let [emailError, setEmailError] = useState("");
    let [passwordError, setPasswordError] = useState("");
    let [accEmail, setAccEmail] = useState("");
    let [registationStage, setRegistationStage] = useState(0);

    useEffect(() => {
        setNavbar(true);
    });

    useEffect(() => {
        if (registationStage === 0 && user.uid) {
            window.location.replace('/');
        }
    }, [user, registationStage]);

    const submitRegistration = (e) => {
        e.preventDefault();
        const email = e.target[0].value;
        const password = e.target[1].value;
        const confirmPassword = e.target[2].value;
        let mailFormat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(!email.match(mailFormat)){
            setEmailError("Please enter a valid email address");
            return;
        }

        if (password !== confirmPassword) {
            setEmailError("Passwords do not match");
            return;
        }

        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            setRegistationStage(1);
            setAccEmail(email);
            submitDetails(email); // Immediately submit to Firestore after successful registration
        })
        .catch((error) => {
            const errorCode = error.code;
            switch (errorCode) {
                case "auth/email-already-in-use":
                    setEmailError("Email already in use");
                    break;
                default:
                    setEmailError("Unknown error occured");
                    setPasswordError("Unknown error occured");
            }
        });
    }

    const submitDetails = async (email) => {
        const dummyValue = "DUMMY_VALUE"; // Replace with any dummy value you want
        const currentDate = new Date();
  
        // Add 3 months to the current date
        currentDate.setDate(currentDate.getDate());
        
        const dateString = currentDate.toDateString();
        localStorage.setItem('exp', dateString);

        await addDoc(collection(db, "users"), {
            type: dummyValue,
            email: email,
            name: dummyValue,
            yearFounded: dummyValue,
            mission: dummyValue,
            EIN: dummyValue,
            NTEE: dummyValue,
            exp: dateString,
            pro: true
        });
        localStorage.setItem('proversion', 'true');

        window.location.replace('/');
    }
    const setEmailToNull=()=>{
        setEmailError("")
      }

    return (
<section class="py-28 bg-primary-50" id="contact">
    <div class="container px-12 md:px-0">
        <section class="bg-gray-50 dark:bg-gray-900">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img class="w-8 h-8 mr-2" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/d9ba458e-f2c2-4311-8fb4-d6fa1dcf09ca.png" alt="logo" /> ChangeFinder
                </a>
                <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 class="text-xl font-bold tracking-tight leading-tight text-gray-900 md:text-2xl lg:text-center dark:text-white">Sign Up</h1>
                        <form class="space-y-4 md:space-y-6" onSubmit={submitRegistration}>
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                            </div>
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Renter Password</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            </div>
                            {emailError ?(
                            <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <strong class="font-bold">Holy smokes!</strong><br/>
                                <span class="block sm:inline">{emailError}</span>
                                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={setEmailToNull}><title >Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                            </span>
                            </div>):null}
                            <div class="flex items-center justify-between">
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="remember" class="text-gray-500 lg:text-xs dark:text-gray-300">I accept the terms and conditions</label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                            <p class="text-sm font-light text-gray-500 dark:text-gray-400">Move back to login</p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>
    )
});
