import React, { Component } from "react";
import { getAuth } from "firebase/auth";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null, // Stores Firebase user details
        };
        this.auth = getAuth();
    }

    componentDidMount() {
        // Check Firebase auth state
        this.auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
    }

    handleSignOut = () => {
        this.auth.signOut().then(() => {
            console.log("User signed out");
        }).catch((error) => {
            console.error("Sign-out error:", error);
        });
    };

    render() {
        const { user } = this.state;

        return (
            <div className="container mx-auto p-6 text-center">
                <h1 className="text-4xl font-bold my-6">User Profile</h1>

                {user ? (
                    <div className="bg-white shadow-lg rounded-lg p-6 max-w-md mx-auto">
                        <img 
                            src={user.photoURL || "https://via.placeholder.com/150"} 
                            alt="User Profile" 
                            className="w-32 h-32 rounded-full mx-auto"
                        />
                        <h2 className="text-2xl font-bold mt-4">{user.displayName || "No Name Provided"}</h2>
                        <p className="text-gray-600">{user.email}</p>
                        <p className="text-gray-500">Login Method: {user.providerData[0].providerId}</p>
                        
                        <button 
                            onClick={this.handleSignOut} 
                            className="mt-4 bg-red-500 text-white py-2 px-6 rounded-lg hover:bg-red-600"
                        >
                            Sign Out
                        </button>
                    </div>
                ) : (
                    <p className="text-lg text-red-600 mt-6">⚠️ No user is logged in. <a href="/login" className="text-blue-500 underline">Login here</a></p>
                )}
            </div>
        );
    }
}

export default Profile;
